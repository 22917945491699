import request from '@/utils/request'

// 登录方法
export function login(account, password) {
  const data = {
    account,
    password,
  }
  return request({
    url: '/login/login',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/login/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/login/getInfo',
    method: 'get',
    headers:{
      loading:false
    }
  })
}
// 获取用户详细信息
export function getGroup() {
  return request({
    url: '/getGroup',
    method: 'get'
  })
}
export function sendEmailCode(query){
  return request({
    url: '/login/sendEmailCode',
    method: 'get',
    params: query
  })
}
// 获取用户详细信息
export function getTeam(query) {
  return request({
    url: '/login/getTeam',
    method: 'get',
    params: query
  })
}
// 注册方法
export function resetPassword(data) {
  return request({
    url: '/login/resetPassword',
    method: 'post',
    data: data
  })
}
// 获取团队列表
export function getTeamList(query) {
  return request({
    url: '/login/getTeamList',
    method: 'get',
    params: query
  })
}
// 获取等级列表
export function getGrade(query) {
  return request({
    url: '/login/getGrade',
    method: 'get',
    params: query
  })
}
