<template>
  <Loading v-show="useLoadingStore().isLoading"></Loading>
  <router-view/>
</template>

<script setup>
import useLoadingStore from '@/store/modules/loading'
</script>
<script>
import useUserStore from "@/store/modules/user";
import {getInfo} from "@/api/login";
import {useI18n} from "vue-i18n";
import {getLanguage, getTheme} from "@/api/basic";
import Loading from '@/components/Loading'

export default {
  name: 'App',
  components: {
    Loading
  },
  created() {
    getTheme().then(res=>{
      this.setStyleTemplate(res.data.config)
    })
    if(useUserStore().token){
      getInfo().then((res)=>{
        useUserStore().userInfo = res.data
      })
    }
    if(!localStorage.getItem('lang')){
      const { locale } = useI18n()
      getLanguage({status:0}).then(res=>{
        locale.value = res.data[0].code;
        localStorage.setItem('lang',res.data[0].code)
      })
    }
  },
  methods:{
    setStyleTemplate (data) {
      data.forEach(function(v) {
        document.body.style.setProperty(v.name, v.value);
      });
    }
  }
}
</script>

<style>

</style>
