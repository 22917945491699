import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n';
import "amfe-flexible"

import AOS from "aos";
import 'aos/dist/aos.css';

// 2. 引入组件样式
import './styles/font.css'
import 'vant/lib/index.css';
import './styles/theme.scss'
import Vant from 'vant';
import router from './router'

const appAOS = new AOS.init();



const app = createApp(App)

app.use(appAOS);
app.use(Vant)
app.use(router)
app.use(store)
app.use(i18n)
app.mount('#app')
