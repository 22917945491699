import {defineStore} from "pinia";

const useLoadingStore = defineStore(
    'loading',
    {
        state: () => ({
            isLoading: false,
            loadingResCount: 0,
        }),
        actions:{
            startLoading() {
                if (this.loadingResCount === 0) {
                    this.isLoading = true;
                }
                this.loadingResCount++;
            },
            endLoading() {
                if (this.loadingResCount <= 0) return;
                this.loadingResCount--;
                if (this.loadingResCount === 0) {
                    this.isLoading = false;
                }
            }
        }
    })

export default useLoadingStore
