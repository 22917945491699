import {getInfo, login} from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import {defineStore} from "pinia";

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      userInfo:{},
    }),
    actions: {
      // 登录
      login(userInfo) {
        const account = userInfo.account.trim()
        const password = userInfo.password
        return new Promise((resolve, reject) => {
          login(account, password).then(res => {
            setToken(res.token)
            this.token = res.token
          getInfo().then((res)=>{
              this.userInfo = res.data
          })
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 退出系统
      logOut() {
        return new Promise((resolve) => {
            this.token = ''
            removeToken()
            resolve()
        })
      }
    }
  })

export default useUserStore
