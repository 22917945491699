import axios from 'axios'
import { getToken } from '@/utils/auth'
import {showConfirmDialog, showNotify, showToast} from 'vant';
import errorCode from '@/utils/errorCode'
import { tansParams } from '@/utils/ruoyi'
import cache from '@/plugins/cache'
import useUserStore from '@/store/modules/user'
import i18n from "@/i18n";
import useLoadingStore from "@/store/modules/loading";
const { t} = i18n.global
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "/dev-api/api",
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['Lang'] = localStorage.getItem('lang')
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
    if (requestSize >= limitSize) {
      console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
      return config;
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                // 请求地址
      const s_data = sessionObj.data;              // 请求数据
      const s_time = sessionObj.time;              // 请求时间
      const interval = 1000;                       // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = 'dataIsBeingProcessedPleaseDoNotResubmit';
        return Promise.reject(new Error(t(message)))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  if (config.headers.loading !== false && config.method === "get") {
    useLoadingStore().startLoading()
  }
  return config
}, error => {
    useLoadingStore().endLoading()
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {

    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    let msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
      return res.data
    }
    if (res.config.headers.loading !== false && res.config.method === "get") {
      useLoadingStore().endLoading()
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        showConfirmDialog({
          title: t('systemPrompt') ,
          confirmButtonText: t('determine'),
          cancelButtonText: t('cancel'),
          message: t('loginStatusHasExpiredPleaseLogInAgain'),
        })
        .then(() => {
          isRelogin.show = false;
          useUserStore().logOut().then(() => {
            location.href = '/#/Login';
          })
        })
        .catch(() => {
          isRelogin.show = false;
        });
    }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      if(msg === "Bad credentials"){
        msg = "UserDoesNotExistOrPasswordIsIncorrect"
      }
      showToast(t(msg))
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      showNotify({ type: 'warning', message: msg })
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      showNotify({ type: 'danger', message: t(msg) });
      return Promise.reject('error')
    } else {
      return  Promise.resolve(res.data)
    }
  },
  error => {
    useLoadingStore().endLoading()
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    showNotify({ type: 'danger', message: message , duration: 5 * 1000});
    return Promise.reject(error)
  }
)


export default service
