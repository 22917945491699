export default {
    username:"用户名",
    phone:"手机号",
    password:"密码",
    email:"邮箱",
    login:"登录",
    register:"注册",
    invitation_code:"邀请码：{code}",
    repeat_password:"重复密码",
    please_fill_in:"请填写",
    home:"首页",
    price:"积分价格",
    chart:"交易",
    finance:"资产",
    mine:"我的",
    deposit:"存款",
    withdraw_money:"取款",
    contact_us:"联系我们",
    market_situation:"市场行情",
    risingList:"涨幅榜",
    declineList:"跌幅榜",
    submitting:"提交中...",
    regularFinancialManagement:"定期理财",
    oneStopFinancialManagementService:"一站式理财服务",
    product:"产品",
    submit:"提交",
    latestPrice:"最新价",
    TodayUpsAndDowns:"今日涨跌",
    phoneReg:"手机注册",
    emailReg:"邮箱注册",
    userReg:"用户注册",
    phoneLogin:"手机登录",
    emailLogin:"邮箱登录",
    userLogin:"用户登录",
    retrievePassword:"找回密码",
    registerAccount:"注册账户",
    tall:"高",
    low:"低",
    min_1:"1分钟",
    min_5:"5分钟",
    min_15:"15分钟",
    min_30:"30分钟",
    min_60:"60分钟",
    hour_4:"4小时",
    day_1:"1天",
    buy_up:"买涨",
    buy_down:"买跌",
    dataIsBeingProcessedPleaseDoNotResubmit:"数据正在处理，请勿重复提交",
    usernameCannotBeEmpty:"用户名不能为空",
    accountCannotBeEmpty:"账户不能为空",
    passwordCannotBeEmpty:"密码不能为空",
    inviterCodeCannotBeEmpty:"邀请码不能为空",
    accountCannotBeDuplicate:"账户不能重复",
    registerSuccess:"注册成功",
    loginSuccess:"登录成功",
    logOutSuccess:"退出成功",
    UserDoesNotExistOrPasswordIsIncorrect:"用户名为空或密码不正确",
    accountCenter:"账户中心",
    asset:"资产",
    totalAccountAssetConversion:"总账户资产折合",
    newShareAllotment:"新股配售",
    helpCenter:"帮助中心",
    setup:"设置",
    financialLending:"金融借贷",
    accountingChangeRecords:"帐变记录",
    systemPrompt:"系统提示",
    loginStatusHasExpiredPleaseLogInAgain:"登录状态已过期，请重新登录",
    selectCycle:"选择周期",
    selectAmount:"选择金额",
    accountBalance:"账户余额：{num} $",
    transactionPairs:"交易对",
    direction:"方向",
    currentPrice:"现价",
    orderAccount:"下单金额",
    expectedRevenue:"预计收益",
    pleaseEnterTheAmount:"请输入金额",
    confirmOrderPlacement:"确定下单",
    insufficientBalance:"余额不足",
    toBeSettled:"待结算",
    settled:"已结算",
    profitAndLoss:"盈亏",
    time:"时间",
    checkoutSuccess :"下单成功",
    invalidOrder :"无效订单",
    remark :"备注：",
    tradingAndPlacingOrders :"交易下单",
    amount :"数量",
    type :"类型",
    status :"状态",
    incomplete :"未完成",
    completed :"已完成",
    rejected :"已拒绝",
    expenditure :"支出",
    income :"收入",
    quit :"退出",
    thirdLevelDistribution :"三级分销",
    bankCardBinding :"银行卡绑定",
    orderRevenue :"订单收入",
    mySuperior:"我的上级",
    numberOfSubordinates:"下级人数",
    totalRevenue:"总收益",
    distributionRevenue:"分销收入",
    nothing:"无",
    recharge:"充值",
    rechargeAddress:"充值地址",
    copyAddress:"复制地址",
    copySuccess:"复制成功",
    copyError:"复制失败",
    myRecommendedLink:"我的推荐链接",
    exchange:"闪兑",
    loginAndReg:"登录/注册",
    hotRoBot:"热门机器人",
    rechargePoints:"充值积分",
    distributionPoints:"分销积分",
    withdrawal:"提现",
    pleaseChooseMethod:"请选择方式",
    rechargeNetwork:"充值网络",
    depositTips:"仅支持向该地址充值{name}，请勿充值其他资产。",
    minRechargeNum:"最小充值：{num}",
    maxRechargeNum:"最大充值：{num}",
    rechargeNum:"充币数量",
    robot:"机器人",
    singleQuestionTime:"单题时间",
    submittedSuccessfully:"提交成功",
    account:"账户",
    creditScore:"信誉分",
    accountInformation:"账号信息",
    notice:"通知",
    onlineService:"在线客服",
    loginIP:"登录IP",
    loginTime:"登录时间",
    rechargeRecords:"充值记录",
    noContent:"暂无内容",
    pendingReview:"待审核",
    pleaseCompleteTheForm:"请填写完成表单",
    distributionRecords:"分销记录",
    gettingPointsByAnsweringQuestions:"答题获得积分",
    yourOpinion:"您的意见",
    yourFuture:"您的未来",
    joinNow:"马上加入",
    operatingPrinciple:"运作原理",
    verificationCode:"验证码",
    buyRobot:"购买机器人",
    confirmPurchase:"确认购买",
    mineRobot:"我的机器人",
    expirationTime:"到期时间",
    running:"运行中",
    expired:"已到期",
    forgotPassword:"忘记密码",
    dailyProfit:"每天利润",
    dailyMaximum:"日最大答题数",
    min:"分钟",
    insufficientLevelGoAndUpgradeIt:"等级不足，快去提升等级吧！",
    monthlyProfit:"每月利润",
    workingHours:"工作时间",
    hour24:"24小时",
    gradeRules:"等级规则",
    total:"合计：",
    myOrder:"我的订单",
    pendingProcessing:"待处理",
    itemInTotal:"共{num}件商品",
    flashExchangeProducts:"闪兑商品",
    flashRefusal:"闪兑驳回",
    invitation:"邀请码",
    emailCodeCantBeEmpty:"邮箱验证码不能为空",
    getEmailCode:"获取邮箱验证码",
    second:"秒",
    myTeam:"我的团队",
    accumulatedTeamSize:"累计团队人数",
    viewTeamList:"查看团队列表",
    accumulatedRevenue:"累计收益",
    accumulatedRecharge:"累计充值",
    accumulatedWithdrawal:"累计提现",
    rebatesForFirstGenerationMembers:"一代成员返利",
    rebatesForSecondGenerationMembers:"二代成员返利",
    thirdGenerationMemberRebate:"三代成员返利",
    teamDataOverview:"团队数据总览",
    lastThreeGenerationsOfData:"最近三代数据",
    firstGenerationData:"一代数据",
    secondGenerationData:"二代数据",
    thirdGenerationData:"三代数据",
    rebateRatio:"返佣比例",
    teamSize:"团队人数",
    problemCountIsMax:"已到达答题上限",
    rechargeFlow:"充值流水",
    teamCommission:"团队分佣",
    inviteToEarnPoints:"邀请获得积分",
    thereNothingMoreLeft:"没有更多了",
    reset:"重置",
    firstGeneration:"LEV1",
    secondGeneration:"LEV2",
    thirdGeneration:"LEV3",
    idTitle:"ID：{id}",
    gradeInviteNum:"邀请{num}个下属团队成员",
    gradeAnswersNum:"答题{num}道问卷题目",
    gradeInvitingPoints:"(邀请一人得{num}分销积分)",
    nowGrade:"当前等级",
    emailCodeError:"邮箱验证码错误",
    determine:"确定",
    cancel:"取消",

    copy:"复制",
    inviteFriendsToRegister:"邀请好友注册",
    todayProfit:"今日利润：{num}",
    taskStatus:"任务状态：{text}",

    aboutUs:"关于我们",
    memberRegistration:"成员登记",
    articlesOfAssociation:"公司章程",
    shareCertificate:"股票证书",
    minutesMeeting:"会议记录",
    goodCertificate:"良好证书",
    taxDocuments:"税务文件",
    certificateEmployment:"在职证明",
    certificateRegistration:"注册证书",
    myCompany:"我们的公司",

    problemCountIsDayMax:"今日已到达答题上限",

    membershipTermsAndConditions:"会员条款及细则"



};
