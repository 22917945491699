import request from '@/utils/request'

export function getCustomerService(query) {
  return request({
    url: '/basic/getCustomerService',
    method: 'get',
    params: query,
    headers:{
      loading:false
    }
  })
}

export function getTheme(query) {
  return request({
    url: '/basic/theme',
    method: 'get',
    params: query,
    headers:{
      loading:false
    }
  })
}
export function getContent(query) {
  return request({
    url: '/basic/content',
    method: 'get',
    params: query,
    headers:{
      loading:false
    }
  })
}
export function getRecharge() {
  return request({
    url: 'getRecharge',
    method: 'get'
  })
}

export function getLanguage(query) {
  return request({
    url: '/basic/lang',
    method: 'get',
    params: query
  })
}
export function getAreaCode(query) {
  return request({
    url: '/basic/areacode',
    method: 'get',
    params: query
  })
}
export function getAbout(query) {
  return request({
    url: '/basic/getAbout',
    method: 'get',
    params: query
  })
}
export function getNotice(query) {
  return request({
    url: '/basic/getNotice',
    method: 'get',
    params: query
  })
}
export function readNotice(query) {
  return request({
    url: '/basic/readNotice',
    method: 'get',
    params: query
  })
}
