import { createI18n } from 'vue-i18n';
import ZH from './lang/zh.js';
import EN from './lang/en.js';
import PT from './lang/pt.js';
import ES from './lang/es.js';
import JP from './lang/jp';
import VN from './lang/vn';
import RU from './lang/ru';
import KR from './lang/kr';
import AR from './lang/ar';

const messages = {
    'zh-CN': { ...ZH  },
    'en-US': { ...EN  },
    'pt': { ...PT  },
    'es': { ...ES  },
    'ja-JP': { ...JP  },
    'vi_VN': { ...VN  },
    'ru-RU': { ...RU  },
    'ko_KR': { ...KR  },
    'ar': { ...AR  },
};



const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: localStorage.getItem('lang') || 'zh-CN', // 首先从缓存里拿，没有的话就用浏览器语言，,
    messages
});
export default i18n;
